import React, { useLayoutEffect } from "react";
import { Link } from "gatsby";
import * as ClientStoriesListingStyles from "./ClientStoriesListing.module.scss";

import { Arrow } from "../../../SVGs/SVGs";

import { gsap } from "gsap";

const ClientStoriesData = [
  {
    title: "A new life for IT Flakes",
    subtitle: "Business Continuity",
    link: "/client/it-flakes",
  },
  {
    title: "Challenging the impossible for DOK32",
    subtitle: "Brand Transformation",
    link: "/client/DOK32",
  },
  {
    title: "Unlocking pure agility for Smartboard",
    subtitle: "Performance Optimization And More ",
    link: "/client/smart-board",
  },
];

const ClientStoriesListing = () => {
  useLayoutEffect(() => {
    const fadeInUpCSTextAnimation = gsap.fromTo(
      `.CSItems-wrapper`,
      {
        opacity: 0,
        y: 100,
      },
      {
        duration: 1,
        opacity: 1,
        y: 0,
        delay: 1.2,
      }
    );

    return () => {
      fadeInUpCSTextAnimation.kill();
    };
  }, []);

  return (
    <section
      className={`es-section__light ${ClientStoriesListingStyles.ClientStoriesListingWrapper}`}
    >
      <div
        className={`CSItems-wrapper ${ClientStoriesListingStyles.ClientStoriesListingGrid}`}
      >
        {ClientStoriesData.map((story, index) => (
          <div
            key={index}
            className={`${ClientStoriesListingStyles.ClientStoriesListingGridItem}`}
          >
            <Link to={story.link}>
              <div>
                <span className="es-subtitle-text">{story.subtitle}</span>
                <h3>{story.title}</h3>
              </div>
              <span>
                <Arrow color="var(--charcoal)" />
              </span>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ClientStoriesListing;

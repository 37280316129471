import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import ClientStoriesListing from "../components/sections/ClientStoriesSections/ClientStoriesListing/ClientStoriesListing";
import Cover from "../components/sections/ClientStoriesSections/Cover/Cover";
import { Helmet } from "react-helmet";

const ClientStories = () => {
  return (
    <>
      <Helmet>
        <title>Client stories</title>
        <meta
          name="description"
          content="endshift is a solution of the last resort. It helps enterprises, small to large businesses, and startups to scale and remove any technological congestion."
        />
        <link rel="canonical" href="https://endshift.io/" />
      </Helmet>

      <Header />
      <main>
        <Cover />
        <ClientStoriesListing />
      </main>
      <Footer />
    </>
  );
};

export default ClientStories;

import React, { useLayoutEffect } from "react";
import * as CoverStyles from "./Cover.module.scss";
import { gsap } from "gsap";

const Cover = () => {
  useLayoutEffect(() => {
    const fadeInUpCSTextAnimation = gsap.fromTo(
      `.CSText-wrapper`,
      {
        opacity: 0,
        y: 100,
      },
      {
        duration: 1,
        opacity: 1,
        y: 0,
        delay: 1.2,
      }
    );

    return () => {
      fadeInUpCSTextAnimation.kill();
    };
  }, []);

  return (
    <section className={`es-section__light ${CoverStyles.CoverWrapper}`}>
      <div className="CSText-wrapper">
        <span className="es-subtitle-text">Client Stories</span>
        <h2>
          We never compromise on perfection.
          <br></br>
          We always design products with high quality standards.
        </h2>
        <p>
          Get the most genuine consulting services for enterprises and startups
          based on real commitment and purveyors of hundreds of digital projects
          for more than 800 clients globally.
        </p>
      </div>
    </section>
  );
};

export default Cover;
